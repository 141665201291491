// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "StudioVsVandalism-module--CarrouselWrapper2--XSQVd";
export var DescriptionWrapper = "StudioVsVandalism-module--DescriptionWrapper--lhwrF";
export var DownloaderWrapper = "StudioVsVandalism-module--DownloaderWrapper--wimw6";
export var EventWrapper = "StudioVsVandalism-module--EventWrapper--Z63Sv";
export var ImageWrapper = "StudioVsVandalism-module--ImageWrapper---6gqx";
export var ImagesWrapper = "StudioVsVandalism-module--ImagesWrapper--bBodA";
export var MobileTile = "StudioVsVandalism-module--MobileTile--N9BGA";
export var PdpWrapper = "StudioVsVandalism-module--PdpWrapper--24iTX";
export var PhotosWrapper = "StudioVsVandalism-module--PhotosWrapper--0DUCj";
export var TitleWrapper = "StudioVsVandalism-module--TitleWrapper--uGZvq";
export var Wrapper = "StudioVsVandalism-module--Wrapper--jFt4A";